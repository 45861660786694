<template>
  <div class="rootDiv">
    <div class="retrieve">
      <div class="searchDiv flex flex-jb">
        <div>
          <el-input @keydown.enter.native="submitSearch" @clear="submitSearch" clearable class="w-450" placeholder="请输入"
            v-model="retrieveForm.searchValue">
            <el-button class="fc-0075F6" slot="append" type="primary" icon="el-icon-search" @click="submitSearch">
            </el-button>
          </el-input>
        </div>
        <div class="flex flex-ac">
          <div class="fs-16 fc-6b9eff pointer" @click="addList">
            <i class="el-icon-circle-plus-outline mr-10 fs-18"></i>新增
          </div>
          <div class="ml-40 fc-666 fs-16" @click="exportExcel">
            <i class="icon iconfont icon-daochu mr-10 fs-18"></i>导出
          </div>
        </div>
      </div>
    </div>
    <TableHeightFull class="tableDiv pall-30">
      <!-- 表格 -->
      <EleTable ref="tableRef" slot="table" :tableData="tableData" :columns="columns" height="100%" :border="false"
        v-loading="tabLoading" :row-style="{ cursor: 'pointer' }">
      </EleTable>
      <!-- 分页 -->
      <PagingComponent slot="after" :page-info="pageInfo" @getTableData="getTableData" />
    </TableHeightFull>
    <limit-activities-details ref="limit-activities-details" @refresh="getTableData"></limit-activities-details>
  </div>
</template>

<script>
import indexMixin from "@/utils/indexMixin"
export default {
  name: 'limitActivities',
  mixins: [indexMixin],
  components: {
    "EleTable": (resolve) => require(["@/components/public/TSimpleTable"], resolve),
    "TableHeightFull": (resolve) => require(["@/components/public/TableHeightFull"], resolve),
    "PagingComponent": (resolve) => require(["@/components/public/PagingComponent"], resolve),
    "limit-activities-details": (resolve) => require(["./limitActivitiesDetails"], resolve)
  },
  data () {
    return {
      retrieveForm: {
        name: '',
        region: '',
        date1: '',
        date2: '',
      },
      tabLoading: false,
      columns: [
        { type: 'index', label: '序号', width: '60', align: 'center', index: this.indexMethod },
        { label: '开始时间', prop: 'startDate', align: 'left', 'show-overflow-tooltip': true },
        { label: '结束时间', prop: 'endDate', align: 'left', 'show-overflow-tooltip': true },
        {
          columnType: 'button', label: '操作', align: 'right', fixed: 'right', width: '150',
          buttons: [
            { type: 'text', text: '编辑', fn: this.editList, },
            { type: 'text', text: '删除', fn: this.deleteList, },
          ],
        },
      ],
      tableData: [],
      //弹框
      dialogFormVisible: false,
      dialogForm: {
        region: '',
        num: 0,
        dataArray: [],
      }
    }
  },
  mounted () {
    this.getTableData()
  },
  methods: {
    //搜索
    submitSearch () { },
    //新增列表
    addList () {
      this.$refs['limit-activities-details'].init()
    },
    //导出
    exportExcel () { },
    //获取表格数据
    getTableData () {
      this.tabLoading = true
      this.$http.get('/timActy/findTimActyList', {
        params: {
          currentPage: this.pageInfo.pageNum,
          pageSize: this.pageInfo.pageSize
        }
      }).then(({ data: result }) => {
        this.tabLoading = false
        this.tableData = result.data.list
        this.pageInfo.total = result.data.total
      }).catch((err) => {
        this.tabLoading = false
      })
    },
    //删除
    deleteList (row) {
      this.$confirm('您确认删除？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(_ => {
        this.$http.post('/timActy/removeTimActy', { timeId: row.timeId }).then(({ data: result }) => {
          this.getTableData()
          this.$message({
            message: '删除成功',
            type: 'success'
          })
        })
      }).catch(_ => { })
    },
    //跳转详情
    editList (row) {
      this.$refs['limit-activities-details'].init(row.timeId)
    },
  },
}
</script>

<style lang="less" scoped>
</style>